.menu {
    position: relative;
    display: inline-block;
  }
  
  .menu-button {
    padding: 1rem;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .sub-routes-container {
    position: absolute;
    min-width: 195px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  .menu:hover .sub-routes-container {
    visibility: visible;
    opacity: 1;
  }
  
  .sub-route {
    text-decoration: none;
    color: black;
    padding: 15px;
    transition: 0.2s ease-in;
    background-color: white;
}
    .sub-route.bold {
        font-weight: 1000;
}
  
  .sub-route:hover {
    color: black;
    background-color: #ffe38b
  }
  