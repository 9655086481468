:root {
  --dark-goldenrod: #ffcd32;
  --pale-goldenrod: #fff5d4;
  --mid-goldenrod: #ffe38b;
  --off-white: #fff;
  --serif-font: 'EB Garamond', serif;
  --sans-serif-font: 'Karla', sans-serif;
  --dark-text-color: black;
}
body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: "EB Garamond", serif;
  color: black;
  font-size: 20px;
  line-height: 20px;
}
p {
  margin-bottom: 10px;
  line-height: 24px;
}
a {
  color: black;
  text-decoration: none;
}
.container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  align-self: center;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  -o-object-fit: fill;
  object-fit: fill;
}
.alert-banner {
  height: 70px;
  top: 80px;
  position: fixed;
  width: 100%;
  font-family: var(--serif-font);
  font-size: 180%;
  letter-spacing: 2px;
  background-color: var(--dark-text-color);
  color: var(--off-white);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  bottom: auto;
  z-index: 3;
  display: block;
}
.alert-banner:hover {
  cursor: pointer;
  color: var(--mid-goldenrod);
}
#blm-banner {
  margin-top: 40px;
}
#vote-banner {
  background-color: var(--pale-goldenrod);
  color: black;
}
#vote-banner:hover {
  cursor: pointer;
  color: var(--dark-goldenrod);
}
.navbar {
  position: fixed;
  top: 0px;
  z-index: 4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 60px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  -o-object-fit: cover;
  object-fit: cover;
}
.brand {
  position: relative;
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
}
.brand.w--current {
  position: relative;
  right: 0px;
  display: block;
  margin-right: 0px;
  padding-left: 0px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.nav-menu {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.nav-link {
  margin-right: 0px;
  margin-left: 0px;
  padding: 20px 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: #fff;
  -webkit-transition: background-color 400ms ease;
  transition: background-color 400ms ease;
  color: black;
  font-size: 20px;
  -o-object-fit: fill;
  object-fit: fill;
}
.nav-link:hover {
  background-color: #fff5d4;
}
.nav-link.w--current {
  color: black;
}
.drop-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 20px 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: #fff;
  cursor: pointer;
}
.drop-wrap:hover {
  opacity: 1;
}
.drop-content {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 200px;
  margin-top: 45px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  align-self: center;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: #fff5d4;
  -o-object-fit: fill;
  object-fit: fill;
}
.drop-link {
  display: block;
  margin: 0px;
  padding: 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: transparent;
  font-size: 20px;
  text-align: center;
  -o-object-fit: fill;
  object-fit: fill;
}
.drop-link:hover {
  background-color: #ffe38b;
  width: 100%;
}
.icon-wrap {
  position: relative;
  overflow: visible;
  width: 16px;
  height: 16px;
  margin-left: 3px;
  -o-object-fit: fill;
  object-fit: fill;
}
.closed-wrap {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  overflow: hidden;
  width: 16px;
  height: 16px;
  background-image: url("../../public/images/general/closed.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}
.opened-wrap {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  overflow: hidden;
  width: 16px;
  height: 16px;
  background-image: url("../../public/images/general/open.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}
.drop-trigger-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 22px;
}
.drop-trigger-text-wrap {
  position: relative;
  overflow: hidden;
  font-size: 20px;
}
.landing {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  padding-top: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.d1 {
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: 'EB Garamond', serif;
  font-weight: 400;
  text-align: left;
}
.welcome-msg-wrap {
  position: relative;
  left: -35px;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 900px;
  padding: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: var(--dark-goldenrod);
}
.d2 {
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'EB Garamond', serif;
  font-size: 48px;
  white-space: nowrap;
}
.slider {
  height: 77vh;
}
.h1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.calendar-section {
  padding-top: 60px;
  padding-bottom: 60px;
}
.hrule {
  width: 940px;
  height: 2px;
  margin-top: 10px;
  background-color: #ffcd32;
}
.footer {
  margin-top: auto;
  padding-top: 20px;
  padding-bottom: 15px;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  background-color: #ffe38b;
}
.page-head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.page-head.calendar {
  margin-bottom: 50px;
}
.flex-vert {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}
.flex-vert-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}
.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.content {
  margin-top: 0px;
  padding-top: 80px;
  margin-bottom: 0px;
}
.paragraph-center {
  margin-bottom: 0px;
  text-align: center;
}
.paragraph-center.sub {
  color: black;
  max-width: 950px;
  margin-bottom: 20px;
}
#court-CJ-card-holder {
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  grid-area: "CJ";
}
.court-grid {
  display: -ms-grid;
  display: grid;
  padding-left: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 60px;
  grid-template-areas: "CJ CJ CJ";
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
}
.people-grid {
  display: -ms-grid;
  display: grid;
  padding-left: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 60px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.resa-grid {
  display: flex;
  padding-right: 40px;
  grid-column-gap: 80px;
  justify-content: center;
}
.prof-pic {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 200px;
}

.prof-pic-with-overlay {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 200px;
  position: relative;
}

.prof-pic-with-overlay:hover .overlay {
  opacity: 0.8;
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: var(--dark-text-color);
}

.overlay-text {
  color: var(--off-white);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.person-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}
.h4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 24px;
}
.sub-head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.people-section {
  margin-top: 0px;
  margin-bottom: 120px;
}
.name {
  max-width: 750px;
  -o-object-fit: fill;
  object-fit: fill;
}
.page-nav-ref {
  position: relative;
  top: -80px;
  color: transparent;
}
.welcome-hero-wrap {
  padding-top: 115px;
}
.slider-wrap {
  position: relative;
  margin-top: -35px;
  margin-left: -153px;
  width: 75vw;
}
.slide-1 {
  overflow: visible;
  background-image: url("../../public/images/home/beerbike-low.jpg");
  background-position: 50% 50%;
  background-size: cover; /* Adjust the percentage as needed */
  background-repeat: no-repeat;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}
.mask {
  overflow: hidden;
}
.slide-2 {
  background-image: url("../../public/images/home/samtom.jpeg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.slide-3 {
  background-image: url("../../public/images/home/oweek2023.jpeg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.slide-4 {
  background-image: url("../../public/images/home/powderpuff2023.jpeg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.slide-5 {
  background-image: url("../../public/images/home/collegenightpic2023.jpeg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.slide-6 {
  background-image: url("../../public/images/home/pumpkin2023.jpeg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.slide-7 {
  background-image: url("../../public/images/home/summit2023.jpeg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.repCard {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.position {
  margin-top: 10px;
  margin-bottom: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: center;
}
.empty-line {
  display: block;
  height: 25px;
}
.sub-hrule {
  width: 400px;
  height: 2px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: #ffe38b;
}
.paa-content {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 2.75fr 0.5fr 0.5fr;
  grid-template-columns: 1fr 2.75fr 0.5fr 0.5fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.mentors-content {
  display: -ms-grid;
  display: grid;
  justify-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-column-gap: 25px;
  grid-row-gap: 20px;
  grid-template-columns: 2fr 8fr 1fr 1fr;
}
.da-content {
  display: -ms-grid;
  display: grid;
  justify-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-column-gap: 25px;
  grid-row-gap: 20px;
  grid-template-columns: 3fr 2fr 4fr 1fr 2fr;
}
.paa-mentors-data {
  margin-bottom: 0px;
  font-size: 20px;
}
.PCA-DF-HeadCaregiver-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.rha-content {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.rha-card-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.from-navbar-ref {
  position: relative;
  top: -80px;
}
.ns-section {
  margin-top: 0px;
  margin-bottom: 80px;
}
.ns-section.hidden {
  display: none;
}
.gallery {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.forms-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 39px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 1.5fr auto;
  grid-template-rows: 1.5fr auto;
  margin-bottom: 100px;
}
.calendar-desk {
  display: block;
}
.calendar-tab {
  display: none;
}
.footer-credit {
  margin-top: 20px;
  color: #919191;
  text-align: center;
}
.pnav-wrap {
  position: fixed;
  left: auto;
  top: auto;
  right: 50px;
  bottom: 50px;
  z-index: 10;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}
.right-arrow {
  display: none;
}
.left-arrow {
  display: none;
}
.calendar-mobile {
  display: none;
}
.form-link {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
}
.form-link:hover {
  color: #ffcd32;
}
.mobile-welcome-crest {
  display: none;
}
.page-title-crest {
  display: none;
}
.flex-vert-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.slider-cover {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 4;
}
.div-block-15 {
  margin-top: 12px;
}
.page-loader {
  position: fixed;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff5d4;
}
.image {
  height: 60px;
  margin-right: 12px;
}
.div-block-16 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.heading {
  font-family: 'EB Garamond', serif;
}
.h2 {
  font-size: 42px;
}
.oweek-team-head {
  margin-bottom: 68px;
  text-align: center;
}
/* The Popup (background) */
.popup-base {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 5; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}
/* Popup Content/Box */
.popup-content {
  background-color: #fefefe;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 2px solid goldenrod;
  width: 80%;
  max-width: 800px;
  max-height: 600px;
  overflow: auto; /* Enable scrolling within the popup if content exceeds */
}

.popup-text-holder {
  padding-left: 10%;
  padding-right: 10%;
}
/* The Close Button */
.close-button {
  color: #aaa;
  background-color: #fefefe;
  float: right;
  font-size: 28px;
  font-weight: bold;
  float: right;
  text-decoration: none;
}
.centered-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* The Popup Buttons */
.popup-button {
  background-color: var(--dark-goldenrod);
  border: none;
  color: var(--dark-text-color);
  padding: 20px 34px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.popup-button:hover {
  background-color: goldenrod;
}

/* The BLM Buttons */
.blm-button {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 10px;
}

/* The Vote Buttons */
.vote-button {
  margin-left: 40px;
}

/* The BLM Contact Info */
.blm-contact-info {
  margin-top: 10px;
  margin-left: 40px;
  margin-right: 40px;
  text-align: center;
}
/* Hidden Additional Text */
.hidden-description {
  display: none;
  font-size: 11px;
  margin-bottom: 0;
}
/* Links */
.link {
  text-decoration: underline;
}

.library-content {
  margin: auto;
  text-align: center;
  max-width: 800px;
  overflow: hidden;
}

.library-forms {
  margin: 50px;
  margin-top: 80px;
  max-width: 40%;
  display: inline-block;
}

.library-pic {
  width: 40%;
  margin: 30px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.donate {
  max-width: 940px;
  background-color: rgb(255, 205, 50);
  color: black;
  padding: 10px 20px;
  border-radius: 0px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
}

.donate:hover {
  background-color: goldenrod;
}

/* iPad Size Screen */
@media screen and (max-width: 991px) {
  #register-private {
    display: none;
  }
  .navbar {
    height: auto;
  }
  .nav-link {
    font-size: 12px;
    text-align: center;
    padding: 15px 10px;
  }
  .drop-wrap {
    padding: 15px 10px;
  }
  .drop-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 180px;
    margin-top: 47px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .drop-link {
    width: 180px;
    font-size: 12px;
  }
  .drop-trigger-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .drop-trigger-text-wrap {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 12px;
    text-align: center;
  }
  .landing {
    padding-top: 110px;
  }

  .landing-wrap {
    width: 100%;
    padding-top: 70px;
  }

  .welcome-hero-wrap {
    padding-top: 65px;
  }
  .welcome-msg-wrap {
    position: relative;
    left: auto;
    width: auto;
  }
  .hrule {
    width: 700px;
  }
  .court-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "CJ CJ";
  }
  .people-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .forms-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .text-block-3 {
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .calendar-desk {
    display: none;
  }
  .calendar-tab {
    display: block;
  }
  .popup-text-holder {
    padding-left: 0px;
    padding-right: 0px;
  }
  .content {
    margin-top: 35px;
    padding-top: 25px;
    margin-bottom: 0px;
  }
}

/* Mobile on Desktop Screen */
@media screen and (max-width: 600px) {
  .container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar {
    display: none;
    overflow: hidden;
  }
  .drop-content {
    display: none;
  }
  .landing {
    width: 100%;
    padding-top: 0px;
  }
  .d1 {
    font-size: 30px;
  }
  .welcome-msg-wrap {
    position: static;
    width: 100vw;
    height: auto;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .d2 {
    font-size: 36px;
    line-height: 47px;
  }
  .slider {
    z-index: 2;
    width: 100%;
    height: 86vh;
  }
  .h1 {
    margin-top: 0px;
  }
  .hrule {
    width: 100%;
  }
  .footer {
    height: auto;
    width: 100%;
  }
  .page-head {
    margin-top: 0px;
    margin-bottom: 50px;
    padding: 40px 50px;
    background-color: white;
  }
  .page-head.calendar {
    margin-bottom: 0px;
    background-color: #fff;
  }
  .footer-content {
    display: none;
  }
  .content {
    padding-top: 45px;
    margin-bottom: 0px;
  }
  .people-grid.reps {
    -ms-grid-columns: 1fr;
    grid-template-areas: "CJ";
  }
  .h4 {
    text-align: center;
  }
  .page-nav-ref {
    display: block;
  }
  .welcome-hero-wrap {
    width: 100%;
    padding-top: 0px;
  }
  .slider-wrap {
    position: static;
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
  }
  .slide-1 {
    background-position: 58% 50%;
    background-attachment: scroll;
  }
  .slide-2 {
    background-position: 79% 50%;
  }
  .slide-4 {
    background-position: 36% 50%;
    background-size: cover;
  }
  .position {
    text-align: center;
  }
  .sub-hrule {
    width: 250px;
  }
  .paa-content {
    padding-right: 20px;
    padding-left: 20px;
    grid-column-gap: 10px;
  }
  .mentors-content {
    padding-right: 20px;
    padding-left: 20px;
  }
  .da-content {
    padding-right: 20px;
    padding-left: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 10px;
  }
  .from-navbar-ref {
    display: none;
  }
  .ns-section {
    padding-right: 20px;
    padding-left: 20px;
  }
  .forms-grid {
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 2fr auto;
    grid-template-rows: 2fr auto;
  }
  .calendar-tab {
    display: none;
  }
  .menu-button {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .landing-wrap {
    width: 100%;
    padding-top: 70px;
  }
  .footer-credit {
    text-align: center;
  }
  .pnav-wrap {
    left: auto;
    top: auto;
    right: 50px;
    bottom: 50px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .pnav-icon-wrap {
    position: relative;
    z-index: 10;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 2px 2px 3px -1px rgba(0, 0, 0, 0.35);
    cursor: pointer;
  }
  .pnav-icon-wrap.back {
    position: absolute;
    left: 0%;
    top: auto;
    right: auto;
    bottom: 0%;
    z-index: 9;
    display: none;
    overflow: hidden;
  }
  .right-arrow {
    width: 50px;
    height: 50px;
  }
  .left-arrow {
    width: 50px;
    height: 50px;
  }
  .calendar-mobile {
    display: block;
  }
  .form-link {
    border-style: solid;
    border-width: 2px;
    border-color: #fff5d4;
    border-radius: 10px;
    text-align: center;
  }
  .mobile-welcome-crest {
    display: block;
    margin-right: 20px;
  }
  .pnav-menu-wrap {
    position: static;
    overflow: hidden;
    margin-bottom: 15px;
  }
  .pnav-main-menu {
    display: block;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .pnav-link-text {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  .pnav-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 220px;
    padding: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #ffe38b;
    cursor: pointer;
  }
  .pnav-menu-icon {
    margin-left: 6px;
  }
  .pnav-submenu {
    margin-bottom: 15px;
  }
  .pnav-submenu.cabinet {
    display: none;
    overflow: hidden;
  }
  .pnav-submenu.income {
    display: none;
  }
  .pnav-submenu.people {
    display: none;
  }
  .pnav-back-icon {
    display: inline;
  }
  .pnav-submenu-wrap {
    display: block;
  }
  .page-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px;
  }
  .page-title-crest {
    display: block;
    margin-right: 15px;
  }
  .alert-banner {
    top: 80px;
    position: fixed;
    font-size: 22px;
  }
  .blm-button {
    padding: 10px 5px;
  }
  .popup-button {
    padding: 10px 17px;
  }
  .library-forms {
    display: block;
    margin: auto;
  }
  .library-pic {
    float: none;
    margin: auto;
    margin-top: 20px;
    width: 70%;
  }
  .paragraph-center.sub {
    margin-left: 20px;
    margin-right: 20px;
  }
  .popup-content {
    width: 80%;
    padding: 10px;
    border: 2px solid goldenrod;
  }
  .resa-grid {
    display: grid;
    margin-left: 30px;
    grid-row-gap: 40px;
  }
}

/* Mobile Size */
@media screen and (max-width: 479px) {
  .slider {
    height: 84vh;
  }
  .h1 {
    text-align: center;
  }
  .h3 {
    text-align: center;
  }
  .paa-content {
    grid-column-gap: 10px;
  }
  .nav-icon {
    border: 1px none #000;
  }

  .pnav-icon-wrap {
    background-color: #fff;
  }
  .alert-banner {
    top: 80px;
    height: 70px;
    font-size: 20px;
  }
  #blm-banner {
    margin-top: 50px;
  }
  .popup-button {
    margin-left: 10px;
    margin-right: 10px;
  }
  .centered-wrapper {
    display: block;
  }
  .second-person {
    margin-top: -15px;
    margin-bottom: 15px;
  }

  .hidden-description {
    display: inline-block;
  }

  .court-grid {
    -ms-grid-columns: 1fr;
    grid-template-areas: "CJ";
    grid-template-columns: 1fr;
  }

  .people-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .PCA-DF-HeadCaregiver-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .people {
    max-height: 480px;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .popup-content {
    width: 80%;
    padding: 10px;
    border: 2px solid goldenrod;
  }
  .resa-grid {
    display: grid;
    margin-left: 30px;
    grid-row-gap: 40px;
  }
}

#repCardHolder {
  -ms-grid-column-align: center;
  justify-self: center;
}

#form {
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#oweek-fellow {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}
