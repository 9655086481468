.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
  }
  
  .slide {
    box-shadow: 0px 0px 7px #666;
    width: 100%;
    height: 100%;
    transition: "transform 2s ease";
  }
  
  .slide-hidden {
    display: none;
  }
  
  .arrow {
    position: absolute;
    filter: drop-shadow(0px 0px 5px #555);
    width: 2rem;
    height: 2rem;
    color: gold;
  }
  
  .arrow:hover {
    cursor: pointer;
  }
  
  .arrow-left {
    left: 1rem;
  }
  
  .arrow-right {
    right: 1rem;
  }
  
  .indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
  }
  
  .indicator {
    background-color: goldenrod;
    height: 0.7rem;
    width: 0.7rem;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.5rem;
    cursor: pointer;
  }
  
  .indicator-inactive {
    background-color: grey;
  }

  @media (max-width: 767px) {
    .indicators {
      display: none;
    }
  }